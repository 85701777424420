.about{
  width: 100%;
}
.about-wrapper{
  padding: 122px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  min-width: 325px;
  &__right{
    margin: -210px 0px 0px 0px;
  }
  &> * :not(:last-child){
    margin: 0px 0px 24px 0px;
  }
  &__left{
    max-width: 460px;
  }

}



.left-block__button{
  background: #FFFFFF;
  color: $text;
}

.advantages{
  margin: 180px 0px 0px 0px;
  background: $blue;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 24px;
  min-height: 240px;
  color: #FFFFFF;
  &__dig{
   font-size: 56px;
   line-height: 115%;
    font-weight: 700;
  }
  &__subinfo{
    text-align: center;
  }
}

@media (max-width:635px){
  .advantages{
    flex-direction: column;
    padding: 76px 0px 66px 0px;
    &__item{
      margin: 0px 0px 65px 0px;
    }
  }
}

.stories{
  margin: 122px 0px 0px 0px;
}

.stories-top{
  margin: 0px 0px 52px 0px;
  display: flex;
    align-items: center;
    flex-direction: column;
  &>*:not(:last-child){
    margin: 0px 0px 25px 0px;

  }

}


.stories-bot{
  display: flex;
  justify-content: center;
  align-items: center;
  &__description{
    max-width:470px;
    padding: 42px 109px 48px 48px;
    box-shadow: 0px 24px 50px 0px rgba(41, 15, 0, 0.5);
    background: #FFFFFF;
    border-radius: 16px;
    position: absolute;
    z-index: 300;
    bottom:-22%;
    right:-10%;
  }
  &__item{
    position: relative;
    z-index: 3;
  }

  &__item:not(:first-child){
    margin:0px 0px 0px 130px;
  }
  &__img-story{
    max-width: 470px;
    z-index: 5;
    position: relative;
  }
}

@media (max-width: 470px){
  .stories-bot__img-story{
    max-width: 301px;
  }
  .description__info{
    max-width: 320px;

  }
  .stories-bot__description{
    bottom:-70%;
    left:10%;
  }
}

.description{
  &__info{
    max-width: 315px;
    margin: 6px 0px 0px 0px;
    font-size: 24px;
    font-weight: 700;
    line-height: 133.33%

  }
}
.toggle-slider-list{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 176px;
}
.toggle-slider{
  background-color: $gold;
  margin: 0px 0px 0px 15px;
  border-radius: 50px;
  width: 16px;
  height: 16px;
}



