.wrapper{
  min-height: 100%;
  overflow: hidden;
  font-family: $prim;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}



.container{

  max-width: 1250px;
  padding: 0px 23px;
  width: 100%;
}

.wrapper-inner{
  padding-top: 20px;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__button{
    display: flex;
    min-height: 48px;
    align-items: center;
    justify-content: center;
    padding: 5px 28px;
    border: 1px solid rgba(51, 51, 51, 0.3);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 87.5%;
    border-radius: 6px;
    transition: all 0.5s;
  }
  &__button:hover{
  background: $text;
  color: #FFFFFF;
  }
    &__logo{
      font-weight: 700;
      font-size: 40px;
      line-height: 120%;
    }

 }




.navbar{

  &__list{
    display: flex;

    flex-wrap: wrap;
  }
  &__item:not(:first-child){
    margin-left: 46px;
  }
  &__item{
    line-height: 200%;
    font-size: 16px;
  }
  &__link:hover::before {
    transform:scaleX(1);
    transition: transform 0.25s;
    transform-origin: left;
  }
  &__link{
    position: relative;
  }
}

.navbar__link:before{
  content: "";
  width:100%;
  height: 1px;
  background-color: black;
  bottom:0px;
  position: absolute;
  left: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.25s;
}

