.service{
  background: #F9E5E1;
  width:100%;
  margin-top:5%;
  padding:0px 0px 270px 0px;
}

.service-content{

  &__top-list{
    display: flex;
    transform: translateY(-120px);
    position: relative;
    justify-content: center;
  }
  &__offer{
    text-align: center;
    position: relative;
    z-index: 5;
  }
  &__offer::before{
    content:url('../../img/decor-green.png');
    position: absolute;
    z-index: -1;
    left:-13%;
    max-width:100%;
    transform: rotate(2deg);
    top:-170px;
  }
  &__offer::after{
    content:url('../../img/decor-yellow.png');
    position: absolute;
    z-index: -1;
    left:-13%;
    max-width:100%;
    transform: rotate(2deg);
    top:-100px;
   }
  &__bottom{
    display:flex;
    justify-content: center;

    margin: 72px 0px 0px 0px;
 }
 &__clients{
   text-align: center;
  margin: 140px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
 }
}

.blue{
  background: $blue;
  max-width: 288px;
 height: 288px;
 margin: 0px 30px 0px 0px;

}
.red{
  background-color: #A16E62;
  max-width: 288px;
  height: 288px;
}
@media (max-width:575px){
  .bottom__img-block{
    position: relative;
    display: block;
    right:-18px;
  }
  .bottom__img-block-cam{
    position: relative;
    display: block;
    left:0;
    right:0;

  }
}

.bottom{
  flex-wrap: wrap;
  width:100%;
  &__right-list-bot{
    display: flex;
    margin: 30px 0px 0px 0px;
  }
  &__right-list{
    flex-direction: column;
    display: flex;
    margin: 0px 0px 0px 30px;
  }

  &__left-item{
    background-color: #F4D5AF;
    border-radius: 16px;
    max-height: 608px;
    width: 570px;
    position: relative;
    z-index: 15;
  }
  &__right-item-cam{
    background-color: #96BE9E;
    height: 288px;
    border-radius: 16px;
    position: relative;
  }
  &__right-bot-blocks{
    border-radius: 16px;
  }
  &__descr-block{
    position: absolute;
    bottom:37px;
    left:45px;
    font-size: 40px;
    line-height: 120%;
    font-weight: 700;
  }
  &__descr-block-cam{
    position: absolute;
    font-size: 24px;
    color: #FFFFFF;
    bottom:37px;
    left:45px;
    font-weight: 700;
  }

  &__descr-block-blue{
    position: absolute;
    font-size: 24px;
    color: #FFFFFF;
    bottom:37px;
    left:45px;
    font-weight: 700;
  }
}

.blue{
  border-radius: 16px;
  position: relative;
}
.red{
  position: relative;
}
.list-item:hover{
  box-shadow: 0px 64px 74px 0px rgba(41, 15, 0, 0.3);
  transform: translateY(-15px);
}


.list-item{
  transition: all 0.6s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #FFFFFF;
  height: 100%;
  padding: 56px 48px 37px 48px;
  border-radius: 16px;
  position: relative;
  max-width: 370px;
  z-index: 5;
  box-shadow: 0px 54px 64px 0px rgba(41, 15, 0, 0.1);

    &__before{
      max-width: 38px;
      position: absolute;
      z-index: 4;
      left: 30px;
      top:25px;
    }
  &__name{
    font-weight: 700;
    font-size: 24px;
    line-height: 175%;
    text-align: right;

  }
  &__icon{
    position: absolute;
    left:-22%;
    z-index: 2;
    top:-8%;
  }
  &__descr{
    margin: 51px 0px 23px 0px;
    color: rgba(51, 51, 51, 0.5);
    font-size: 16px;
    line-height: 200%;

  }
  &__button{
    line-height: 200%;
    text-align: left;
    width: 30%;
  }
  &__button:hover{
    text-decoration: underline;
  }
}
.list-item:nth-child(1){
  margin-right: 30px;
}
.list-item:nth-child(2){
  margin-right: 30px;
}


.service-body{
  &__title{
    margin: 20px 0px 28px 0px;
  }
  &__descr{
    display: block;
    text-align: center;
    width: 100%;
  }
}

.title-def{
  font-size: 56px;
  line-height: 120%;
}

.clients{
  &__title{
    margin: 20px 0px 70px 0px;
    max-width: 970px;
  }
  &__icon-list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:85px;
    margin: 0px 0px 70px 0px;
  }
}

.icon-item{
  max-width: 127px;
  width: 100%;
  display: block;
  transition: all 0.5s;
}
.icon-item:hover{
  transform: scale(1.2);
  cursor: pointer;
}
.icon-item:not(:last-child){
  margin: 0px 30px 0px 0px;
}



