@import "general/normalize";
@import "general/fonts";
@import "general/variables";
@import "general/global";
@import "general/blocks";
@import "blocks/service";
@import "blocks/page";
@import url('https://fonts.googleapis.com/css2?family=Caladea:wght@400;700&display=swap');
@import "blocks/about";
@import "blocks/testim";

@import "blocks/letter";
