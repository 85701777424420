.footer{
  background-color: #333333;
  padding: 90px 0px 0px 0px;

}

.footer-wrapper{
  display: flex;

  justify-content: space-between;
  &__first-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 150px;
  }
  &__icons{
    display: flex;
    gap:18px;

  }
  &__logo{
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: #FFFFFF;
    text-align: left;
    width:100%;
    margin: 0px 0px 20px 0px;
  }
  &__name{
    margin: 0px 0px 17px 0px;
  }
  &__fourth-col{
    display: flex;
    flex-direction: column;
  }
  &__adress{
    font-size: 16px;
    line-height: 200%;
    color: #FFFFFF;
    opacity: 0.5;
  }
}



.nav-footer__link:hover{
  text-decoration: underline;
}



.footer-wrapper__second-col, .footer-wrapper__third-col{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icons-item{

  width: 25px;
  min-height: 25px;
  height: 100%;
  width: 100%;
  display: block;
  transition: all 0.3s;
}

.icons-item:hover{
  transform: scale(1.1);
   }

.nav-footer{
  font-weight: 400;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 200%;
}

.footer-container{
  padding-bottom: 70px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-copyright{
  max-width: 1250px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    padding: 42px 23px 53px 23px;
    &__left{
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
      line-height: 200%;
    }
    &__right{
      display: flex;
      gap:60px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      line-height: 200%;
    }
}
