body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  font-family: "Poppins";
  color: #000000;
  background-color: #D0DFEF;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}


* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: initial;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

picture {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  width: 100%;
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

.container {
  width: 100%;
  max-width: 117em;
  margin: 0 auto;
  padding: 0 5px;
}






