@media (max-width: 1170px) {
  .stories-bot{
    flex-wrap: wrap;
   &__item:last-child{
    display: none;
   }

  }

}
@media (max-width:355px){
  .page-content{

    &__bg-pic{
      max-width:350px;
      min-height: 300px;
      width:100%;
      left:0;
    }
    .justice-item{
      display: none;
    }
    .client-item{
      max-width: 90px;
      left:-10px !important;
    }
  }
}
@media (max-width:400px){
  .page-content{

    &__bg-pic{
      max-width:350px;
      min-height: 300px;
      width:100%;
      left:0;
    }
    .justice-item{
      left:260px !important;
      max-width: 150px;
    }
    .client-item{
      max-width: 90px;
      left:-10px !important;
    }
  }
}
@media (max-width: 950px) {
  .service-content__bottom{
    flex-wrap: wrap;
    align-items: center;
  }
  .testim-wrapper{
    flex-wrap: wrap;
    flex-direction: column;

    align-items: center;
  }
  .letter-wrapper{
    max-width: 375px !important;
    align-items: center;

    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top:35%;
    &__left{
      width: 100% !important;
    }
  }
  .right-bot{
    min-height: 271px;
  }
  .right__call{
    font-size: 24px !important;
    width: 210px !important;
    line-height: 32px;
  }


  .bottom{
    justify-content: center;
    &__right-item-cam{
      max-width: 530px;
      margin: 30px 0px 0px 0px;
    }
    &__left-item{
      max-width: 550px;
      height: 500px;
    }
    &__right-list{
      margin: 0px !important;
      align-items: center;
    }

  }
}



@media (max-width: 775px) {

  .page-content{
    &__bg-pic{
      max-width: 350px;
      min-height: 200px;
      position: relative;
      left:-100px;
    }
    .justice-item{
      left:120px;
    }
    .client-item{
      left:-140px;
    }
  }

  .clients__icon-list{
    flex-wrap: wrap;
  }
  .icon-item{
    margin: 30px 30px 0px 0px !important;
    max-width: 130px !important;
  }
  .left-block{
    max-width: 400px !important ;
  }

  .stories-top__descr{
    text-align: center;
  }

  .testim-left__image{
    display: block;
  }

}

@media (max-width: 575px) {
.bottom__right-list-bot{
  justify-content: center;
  flex-wrap: wrap;
}
.testim-left__image{
  max-width: 250px;
  display: block;
}
.bottom__right-item-cam{
  max-width: 329px;
  height: 288px;
}
.bottom__left-item{
  max-width: 329px;
  height: 352px;
}

.blue{
  margin: 0 !important;
  width: 329px;
}
.red {
  margin: 30px 0px 0px 0px !important;
  width: 329px;
}
.about-wrapper__left{
  max-width: 100%;
}
.stories-top__descr{
  text-align: center;
}

.footer-wrapper{
  flex-direction: column;
  align-items: flex-start;
  gap: 65px;
}
.footer-copyright{
  flex-direction: column;
  gap:32px;
  align-items: center;
  &__right{
    font-size: 18px;
    padding-bottom: 20px;
  }
}

}

@media (max-width: 850px){
  .service-content__top-list{
    flex-wrap: wrap;
    justify-content: center;

  }
  .list-item{
    width:320px;

  }
  .list-item{
    margin-top: 30px;
  }
  }

@media (max-width: 710px){
  .list-item{
  margin: 30px 0px 0px 0px !important;
  }
}



@media (max-width: 825px){
  .page-content{
    &__title{
      font-size: 56px;
    }
  }
}

@media (max-width: 630px){
  main{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .page{
    margin: -100px 0px;
  }

  .page-content{
    padding: 0px 0px 176px 0px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;

    &__title{

      font-size: 56px;
      line-height: 64px;
    }

    &__left{
      margin: -30px 0px 0px 0px;
    }
    &__bg-pic{
      max-width:350px;
      min-height: 300px;
      width:100%;
      left:0;
    }
    .justice-item{
      left:250px;
    }
    .client-item{
      left:-40px;
    }
  }
  .wrapper-inner__button{
    display: none;
  }
  .navbar{
    position: fixed;

    top:0;
    left:-100%;
    width:100%;
    height: 100%;
    background-color: #FFFFFF;
    padding:  100px 30px 30px 30px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-left: 2px solid black;
  }
  .wrapper-inner__burger-menu{
    position: relative;
    margin-right: 37px;
    z-index: 5;
    width:25px;
    height: 20px;
    cursor: pointer;
  }

  .wrapper-inner__burger-menu._active{
    left:0;
  }
  .wrapper-inner__burger-menu span,
  .wrapper-inner__burger-menu::after,
  .wrapper-inner__burger-menu::before{

    position: absolute;
    height: 10%;
    width: 100%;
    transition: all .4s ease 0s;
    background-color: $text;

  }
  .wrapper-inner__burger-menu::before,
  .wrapper-inner__burger-menu::after{
    content: '';
  }
  .wrapper-inner__burger-menu::before{
    top:0;
  }
  .wrapper-inner__burger-menu::after{
    bottom:0;
  }
  .wrapper-inner__burger-menu span{
    top:50%;
    transform: scale(1) translate(0px, -50%);

  }

  .about{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-wrapper{
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .bg-pic-about{
    max-width:250px;
      min-height: 300px;
      width:100%;
      margin: 0px 0px 45px 0px;
  }
}
