.letter{
  background-color: #495D4D;
  width:100%;
  margin-top: 15%;
  padding: 0px 0px 128px 0px;
 }



 .letter-wrapper{
  box-shadow: 0px 24px 64px 0px rgba(2, 41, 0, 0.1);
  transform: translateY(-120px);

  display:flex;
  background: #FFFFFF;
  overflow: hidden;
  border-radius: 25px;

  &__left{
    height: 100%;
    width: 50%;
    padding: 0px 65px 0px 0px;
    max-width: 585px;
    padding: 75px 65px 80px 65px;
    }
  &__title{
    font-size: 40px;
    line-height: 120%;
    font-weight: 700;
  }
  &__info{
    color: rgba(51, 51, 51, 0.5);
    margin: 16px 0px 36px 0px;
    font-size: 16px;
  }
  &__name{
    border: 1px solid rgba(218, 218, 218, 1);
    padding: 9px 0px 7px 24px;
    border-radius: 6px;
  }
  &__email{
    border: 1px solid rgba(218, 218, 218, 1);
    padding: 9px 0px 7px 24px;
    border-radius: 6px;
    margin: 16px 0px 16px 0px;
  }
  &__right{

    max-width: 625px;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    min-height: 590px;
  }
  &__message{
    max-width: 456px;
    min-height: 95px;
    border: 1px solid rgba(218, 218, 218, 1);
    padding: 9px 0px 7px 24px;
    border-radius: 6px;
    margin:0px 0px 16px 0px;
  }
 }

.right{
  &__top{
    background-color: #FFF0DD;
    display: flex;
    min-height: 370px;
    flex-direction: column;
    justify-content: center;
    padding: 75px 111px 65px 125px;
  }
  &__call{
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
    position: relative;
  }
  &__call::before{
    content: url("../../img/call.svg");
    position: absolute;
    width:48px;
    height: 48px;
    display: block;
    background: #333333;
    border-radius: 50%;
    padding-left: 13px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    left:-60px;

  }
  &__mail{
    font-size: 20px;
    position: relative;
    line-height: 160%;
    color:rgba(51, 51, 51, 1);
  }
  &__mail::before{
    content: url("../../img/mail.svg");
    position: absolute;
    width:48px;
    height: 48px;
    display: block;
    background: #333333;
    border-radius: 50%;
    padding:11px 12px;
    top:-10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    left:-60px;

  }
  &__descr{
    color: rgba(51, 51, 51, 0.5);
    margin: 16px 0px 31px 0px;
    font-size: 16px;
  }
  &__descr-bot{
    color: rgba(51, 51, 51, 0.5);
    line-height: 32px;
    font-size: 16px;
  }
  &__mail-bot{
    max-width: 300px;
    margin: 0px 16px 0px 0px;
    border-radius: 6px;
    border: 1px solid rgba(218, 218, 218, 1);
    padding: 9px 0px 7px 24px;
  }
}

.right__bot{
    min-height: 241px;
    background-color: #96BE9E;
    padding: 44px 64px 80px 64px;
}
.bot{
  &__name{
    font-size: 40px;
    line-height: 120%;
    color: rgba(255, 255, 255, 1);
    margin: 0px 0px 15px 0px;
  }
}

.send-form{
  display: flex;
}

@media (max-width: 950px){
  .send-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}


//  ---------------------------------------------------------- BLOG ------------------------------------------------------  //

.blog{
  width: 100%;

  background-color: #495D4D;
}

.blog-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__top{
    text-align: center;
  }
  &__bot{
    display: flex;
    justify-content: space-between;
    gap: 32px;
    margin:0px 0px 80px 0px;
  }
  &__title{
    color: #FFFFFF;
    margin: 25px 0px 25px 0px;
  }
  &__descr{
    color: #FFFFFF;
    margin: 0px 0px 72px 0px;
  }
  &__date{
    font-size: 16px;
    line-height: 200%;
    background: rgba(0, 0, 0, 0.3);
    color: #FFFFFF;
    max-width: 115px;
    padding: 0px 10px 0px 10px;
    display: block;
    position: absolute;
    white-space: nowrap;
    border-radius: 6px;
    z-index: 10;
    margin: 32px 0px 0px 24px;
  }

  &__button{
    background: $gold;
    transition: all 0.5s ease;
  }
  &__button:hover{
    transform: translateY(-3px);

    background: $text;
  }

@media (max-width:950px){
  .blog-wrapper__bot{
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

}

}
.blog-img{
  max-width: 365px;
  width:100%;
  display: block;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
  z-index: 5;
  opacity: 0.5;
  transition: all 0.5s ease;
}

.blog-wrapper__item:hover .blog-img{
  opacity:1;
  cursor: pointer;
}

.blog-wrapper__descr-blog{
  background: #FFFFFF;
  max-width: 365px;
  padding: 24px 32px 18px 32px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  font-size: 24px;
  line-height: 133.3%;
  font-weight: 700;
}



