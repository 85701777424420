
body{
  background: #FFFFFF;
}
.page{
  width:100%;
  padding: 170px 0px 176px 0px;

}


.page-content{
  width: 100%;
  display: flex;
align-items: center;
  gap: 182px;

  &__left{
    max-width: 518px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    flex-basis: 100%;
  }
  &__left::before{
    content: '';
    position: absolute;
    background: #FFF0DD;
    width: 60%;
  }
  &> * :not(:last-child){
    margin: 0px 0px 24px 0px;
  }
  &__title{
    font-size: 72px;
    line-height: 111%;
  }
  &__bg-pic{
    position: relative;
  }
  &__right{
    position: relative;

  }


}

.btn:hover{
  background-color: $gold;
    border: none;
}
.btn{
  transition: all 0.6s;
}
.justice-item{
  position: absolute;
  z-index: 10;
  right: -100px;
  bottom: -50px;
}

.client-item{
  position: absolute;
  z-index: 10;
  left:-80px;
  top:60px;
}

@media (max-width: 1150px){
  .justice-item{
    max-width: 200px;
  }
  .client-item{
    max-width: 100px;
  }

}




.gold{
  color: $gold;
  font-size: 18px;
  line-height:177%;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  }

  .text-def{
    font-size: 20px;
    line-height: 160%;
    color: $text;
  }

  .btn{
    display: flex;
    background: $text;
    color: rgba(255, 255, 255, 1);
    min-height: 48px;
    align-items: center;
    justify-content: center;
    padding: 5px 38px;
    border: 1px solid rgba(51, 51, 51, 0.3);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 87.5%;
    border-radius: 6px;
}

