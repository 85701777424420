.testim{
  margin-top:5%;
  &__right{
    max-width: 470px;
    padding: 46px 0px 0px 0px;
  }
  &> * :not(:last-child){
    margin: 0px 0px 25px 0px;
  }
  &__title{
    max-width: 331px;
  }
  &__author{
    padding: 0px 0px 0px 100px;
    margin: 43px 0px 0px 0px;
  }
}

.testim-wrapper{
  display: flex;

  justify-content: space-evenly;
}

.author{
  &__name{
position: relative;
font-size: 24px;
margin:0 !important;
font-weight: 700;
line-height: 133.33%;
 }
 &__name::before{
   content: url("../../img/people.svg");
   position: absolute;
   left:-26%;
   top:-10px;
 }
 &__social{
   color: rgba(51, 51, 51, 0.5);
  line-height: 200%;
  font-size: 16px;
 }
}

.trstim-left__image img{
  display: block;
}


@media (max-width:450px){
  .author__name::before{
    left: -40%;
  }
}


